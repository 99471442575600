/* @import url("https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap"); */
@font-face {
  font-family: 'JosefinSlab';
  src: local('JosefinSlab'),
    url('./fonts/JosefinSlab-VariableFont_wght.ttf') format('truetype');
}
@font-face {
  font-family: 'Tangerine';
  src: local('Tangerine'),
    url('./fonts/Tangerine-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Raleway';
  src: local('Raleway'),
    url('./fonts/Raleway-VariableFont_wght.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: "Raleway" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(to right, rgb(218, 154, 189), rgb(219, 180, 184));
}

