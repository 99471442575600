@font-face {
  font-family: 'JosefinSlab';
  src: local('JosefinSlab'),
    url('./fonts/JosefinSlab-VariableFont_wght.ttf') format('truetype');
}
@font-face {
  font-family: 'Tangerine';
  src: local('Tangerine'),
    url('./fonts/Tangerine-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Raleway';
  src: local('Raleway'),
    url('./fonts/Raleway-VariableFont_wght.ttf') format('truetype');
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(255, 255, 255);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
