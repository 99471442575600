@font-face {
  font-family: 'JosefinSlab';
  src: local('JosefinSlab'),
    url('./fonts/JosefinSlab-VariableFont_wght.ttf') format('truetype');
}
@font-face {
  font-family: 'Tangerine';
  src: local('Tangerine'),
    url('./fonts/Tangerine-Regular.ttf') format('truetype');
}

@import url('https://unpkg.com/bs-brain@2.0.4/components/testimonials/testimonial-3/assets/css/testimonial-3.css');

html, body {
  /*
  --section-background-color: linear-gradient(
    to bottom left,
    rgba(17, 16, 16, 0.582),
    rgba(12, 8, 24, 0.904)
  );

  --image-gradient: linear-gradient(
    to bottom left,
    rgba(17, 16, 16, 0.678),
    rgba(12, 10, 22, 0.863)
  );
  */
  min-height: 100%;
  max-width: 100%;
  min-width: 100%;
  overflow-x: hidden;
  position: relative;
  --imp-text-color: #d865d2;
}

a:link { text-decoration: none; }


a:visited { text-decoration: none; }


a:hover { text-decoration: none; }


a:active { text-decoration: none; }

.purple {
  color: var(--imp-text-color) !important;
}

.purpledark {
  color: #5d2f52;
}

button:focus {
  box-shadow: none !important;
}


/* --------- */
/*Scrollbar   */
/* --------- */

::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  /*background: #dbb4b8;*/
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background:  #9c6c89;
  border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background:  #9c6c89;
  border-radius: 12px;
}

/* --------- */
/* Navbar Section  */
/* --------- */
.sticky {
  background-color: linear-gradient(to right, #da9abd, #dbb4b8) !important;
  transition: all 0.3s ease-out 0s !important;
  box-shadow: 0px 10px 10px 0px rgba(9, 5, 29, 0.171) !important;
  backdrop-filter: blur(15px) !important;
}

.navbar {
  position: fixed !important;
  transition: all 0.3s ease-out 0s !important;
  padding: 0.3rem 2rem !important;
  font-size: 1.4rem !important;
}

.navbar-toggler {
  position: relative !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.navbar-toggler span {
  display: block !important;
  background-color: rgb(218, 154, 189) !important;
  height: 4px !important;
  width: 27px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  transform: rotate(0deg) !important;
  left: 0 !important;
  opacity: 1 !important;
}

.navbar-toggler:focus,
.navbar-toggler:active {
  outline: 0 !important;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
  transition: transform 0.35s ease-in-out !important;
  transition: transform 0.35s ease-in-out !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(135deg) !important;
  opacity: 0.9 !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  height: 12px !important;
  visibility: hidden !important;
  background-color: transparent !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(-135deg) !important;
  opacity: 0.9 !important;
}

@media (max-width: 767px) {
  .navbar {
    padding: 1rem 2rem !important;
    font-size: 1.4rem !important;
    background-color: var(--image-gradient) !important;
    backdrop-filter: blur(10px) !important;
    box-shadow: 0px 10px 10px 0px rgba(9, 5, 29, 0.171) !important;
    /* background-color: blue !important; */
  }
  .navbar-nav .nav-item a::after {
    display: none !important;
  }
  
}

.logo {
  height: 4.4em !important;
  width: 5.5em !important;
}

.navbar-nav .nav-link {
  color: white !important;
  padding-right: 1rem !important;
  padding-left: 0.2rem !important;
}

.nav-link {
  padding: 0.8rem 1rem !important;
}

@media (max-width: 767px) {
  .nav-link {
    padding: 0.7rem 1rem !important;
  }
}

.navbar-nav .nav-item {
  position: relative;
  margin-left: 20px;
}

.navbar-nav .nav-item a {
  font-weight: 400;
  transition: all 0.3s ease-out 0s;
  position: relative;
  z-index: 1;
}

.navbar-nav .nav-item a::after {
  content: "";
  position: relative;
  display: block;
  height: 5px;
  width: 0;
  border-radius: 16px;
  background: var(--image-gradient);
  bottom: 1px;
  left: 0;
  z-index: -1;
  transition: all 0.3s ease-out 0s;
}

.navbar-nav .nav-item a:hover::after {
  width: 100%;
}

/* --------- */
/* Home section */
/* --------- */

#tsparticles {
  position: fixed !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  width: 100%;
  height: 100%;
}

.home-header {
  padding-top: 20px !important;
  padding-left: 50px !important;
  padding-right: 50px !important;
}

.home-h1{
  font-size: 5em !important;
  font-family: "Tangerine" !important;
}

.home-section {
  position: relative;
  z-index: 1;
  background-image:  url(./Assets/BG2.webp);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 500px !important;
  padding-top: 00px !important;
}

.home-content {
  padding: 7rem 0 0rem !important;
  color: whitesmoke;
  text-align: left;
}

.heading {
  font-size: 2.4em !important;
  padding-left: 50px !important;
}

.heading-name {
  font-size: 2.5em !important;
  padding-left: 45px !important;
}

.main-name {
  color: var(--imp-text-color) !important;
}

.Typewriter__wrapper {
  font-size: 2.0em !important;
  color: white !important;
  font-weight: 300 !important;
}
.Typewriter__cursor {
  font-size: 2.0em !important;
  color: white !important;
}

@media (max-width: 767px) {
  .Typewriter__wrapper {
    font-size: 1.4em !important;
    font-weight: 500 !important;
  }
  .Typewriter__cursor {
    display: inline-flex !important;
  }
}

.myAvtar {
  justify-content: center !important;
  padding-top: 5em !important;
}

@media (max-width: 767px) {
  .myAvtar {
    padding-top: 2em !important;
    padding-bottom: 2em !important;
  }
}

.home-about-section {
  padding-bottom: 70px !important;
  padding-top: 70px !important;
  position: relative;
}

.home-about-description {
  color: white !important;
  padding-top: 30px !important;
  padding-bottom: 20px !important;
  text-align: center;
}

.home-about-welcome {
  color: white !important;
  padding-top: 0px !important;
  padding-bottom: 20px !important;
}

.home-about-mission {
  color: white !important;
  padding-top: 50px !important;
  padding-bottom: 0px !important;
  text-align: center;
}

.home-about-body {
  padding-top: 50px;
  font-size: 1.2em !important;
  text-align: justify;
}

.social-icons {
  display: inline-block !important;
  padding-right: 15px;
  padding-left: 15px;
}


/* --------- */
/* Footer */
/* --------- */
.footer {
  background-color: var(--image-gradient);
  top: 0;
  bottom: 0 !important;
  padding-top: 10px !important;
  padding-bottom: 8px !important ;
}


.footer-body {
  z-index: 1;
  text-align: center !important;
  text-decoration: none;
}

@media (max-width: 767px) {
  .footer-copywright {
    text-align: center !important;
  }

  .footer-body {
    text-align: center !important;
  }
}

.footer h3 {
  font-size: 1em;
  color: white !important;
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
  text-decoration: none !important;
}
.footer-icons {
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
  padding: 0 !important;
}


/* --------- */
/* About */
/* --------- */

.about-section {
  position: relative !important;
  padding-top: 150px !important;
  padding-bottom: 30px !important;
  background-image: var(--section-background-color) !important;
  color: white !important;
  z-index: -1;
}

.about-blur {
  background-color: linear-gradient(to right, #da9abd, #dbb4b8) !important;
  background-color: transparent !important;
  transition: all 0.3s ease-out 0s !important;
  font-family: Tangerine !important;
  font-size: 3em !important;
}


/* --------- */
/* Newsletter */
/* --------- */


.Newsletter {
  position: relative;
  padding-bottom: 20px !important;
  padding-top: 0px !important;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.Newsletter section {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Newsletter h1 {
  font-size: 33px;
  font-weight: 400;
  text-align: center;
  margin: 40px 0 10px 0;
  color: white;
}
.Newsletter p {
  width: 400px;
  font-size: 16px;
  line-height: 24px;
  padding-left: 20px !important;
  padding-right: 20px !important;
  color: white;
  margin-bottom: 40px;
}
.Newsletter p em {
  display: block;
  margin-top: 10px;
  font-size: 14px;
}
.Newsletter input {
  display: inline-block;
  width: 300px;
  height: 44px;
  margin: 5px 10px 15px 0;
  padding: 10px 11px;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
  color: #869ab8;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dde1ed;
  border-radius: 2px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.05);
  outline: none;
  box-sizing: border-box;
}
.Newsletter input.error {
  border: 1px solid #fa5252;
}
.Newsletter button {
  position: relative;
  display: inline-block;
  height: 44px;
  padding: 4px 10px;
  letter-spacing: 0.025em;
  font-size: 16px;
  font-weight: 400;
  border-radius: 2px;
  border: none;
  color: #fff;
  background-color: #9c6c89;
  box-sizing: border-box;
  outline: none;
  cursor: pointer;
}


/* --------- */
/* Contact */
/* --------- */


.Contact {
  position: relative;
  padding-bottom: 20px !important;
  padding-top: 0px !important;
  display: fixed;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.Contact section {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Contact h1 {
  font-size: 33px;
  font-weight: 400;
  text-align: center;
  margin: 40px 0 10px 0;
  color: white;
}
.Contact p {
  width: 400px;
  font-size: 16px;
  line-height: 24px;
  color: white;
  margin-bottom: 40px;
}
.Contact p em {
  display: block;
  margin-top: 10px;
  font-size: 14px;
}
.Contact input {
  display: inline-block;
  width: 90%;
  height: 44px;
  margin: 5px 10px 15px 0;
  padding: 10px 11px;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
  color: #869ab8;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dde1ed;
  border-radius: 2px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.05);
  outline: none;
  box-sizing: border-box;
}

.Contact input.error {
  border: 1px solid #fa5252;
}

.Contact textarea {
  display: inline-block;
  width: 90%;
  height: 264px;
  margin: 5px 10px 15px 0;
  padding: 10px 11px;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
  color: #869ab8;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dde1ed;
  border-radius: 2px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.05);
  outline: none;
  box-sizing: border-box;
}

.Contact button {
  position: relative;
  display: inline-block;
  height: 44px;
  padding: 4px 10px;
  letter-spacing: 0.025em;
  font-size: 16px;
  font-weight: 400;
  border-radius: 2px;
  border: none;
  color: #fff;
  background-color: #9c6c89;
  box-sizing: border-box;
  outline: none;
  cursor: pointer;
}

/* --------- */
/* Datenschutz */
/* --------- */

.ds-about-description {
  color: white !important;
  padding-top: 150px !important;
  padding-bottom: 20px !important;
  text-align: left;
}

/* ------ */
/* KURS */

.kurs-section {
  position: relative;
  z-index: -1;
  background-image:  url(./Assets/kurs2.webp);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 200px !important;
  padding-top: 60px !important;
}

.kurs-about-section {
  font-size: 18px;
  position: relative;
  padding-bottom: 70px !important;
  padding-top: 20px !important;
}

.contact-section {
  position: relative;
  z-index: 1;
  background: linear-gradient(
    rgba(0, 0, 0, 0.4),
    rgba(0, 0, 0, 0.1)
  ), url(./Assets/contactbg.webp);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 200px !important;
  padding-top: 60px !important;
}

.contact-section-content {
  position: relative !important;
  padding-top: 150px !important;
  padding-bottom: 30px !important;
  color: white !important;
}

/* --------- */
/* Blog */
/* --------- */

.blog-section {
  position: relative !important;
  padding-top: 150px !important;
  padding-bottom: 30px !important;
  background-image: var(--section-background-color) !important;
  color: white !important;
  z-index: 1;
  
}

.blog-button {
  display: inline-block;
  height: 44px;
  padding: 4px 10px !important;
  margin-bottom: 0px !important;
  letter-spacing: 0.025em !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  border-radius: 2px !important;
  border: none !important;
  color: #fff !important;
  background-color: #9c6c89 !important;
  box-sizing: border-box !important;
  outline: none !important;
  cursor: pointer !important;
}

.blog-card{
  color: #fff !important;
  height: 100% !important;
  font-size: 18px;
}

.blog-card-main{
  color: #fff !important;
  height: 100% !important;
  font-size: 16px;
  padding: 4px 10px !important;
  
}

.blog-card-heading{
  font-size: 25px;
  font-weight: 400;
  text-align: center;
}

.plus-section {
  position: relative;
  z-index: -1;
  background-image:  url(./Assets/Plus_Logo3.webp);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 200px !important;
  padding-top: 60px !important;
}

.plus-about-section {
  font-size: 18px;
  position: relative;
  padding-bottom: 70px !important;
  padding-top: 20px !important;
}

/* --------- */
/* Impressum */
/* --------- */

.imp-about-section {
  font-size: 16px;
  position: relative;
  padding-bottom: 70px !important;
  padding-top: 20px !important;
}

/* --------- */
/* Newsletter */
/* --------- */
.newsletter-section {
  position: relative;
  z-index: 1;
  background: linear-gradient(
    rgba(0, 0, 0, 0.4),
    rgba(0, 0, 0, 0.1)
  ), url(./Assets/Blog7_Foto5.webp);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 200px !important;
  padding-top: 60px !important;
}

.newsletter-section-content {
  position: relative !important;
  padding-top: 150px !important;
  padding-bottom: 30px !important;
  color: white !important;
}

.slick-dots li.slick-active button:before {
  color:White !important;
}